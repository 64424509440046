<template>
  <div>
    <v-row class="ma-2">
      <v-col>
        <v-btn v-if="this.checkPermission([128, 8, 4])" to="products/new" color="red" class="white--text"> <v-icon left>mdi-plus</v-icon>Добавить </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-2">
      <v-col>
        <v-data-table :headers="headers" :items="products" :loading="isMenuListLoading" disable-pagination hide-default-footer>
          <template v-slot:item.image="{ item }">
            <v-img v-if="item.image !== null" max-width="80" max-height="80" :src="url() + item.image.path" class="ma-4">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0 align-center jystify-ceter">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn :to="`${currentPath}/${item.token}`" color="red" text>
              <v-icon left>mdi-pencil-outline</v-icon>
              Редактирование
            </v-btn>
          </template>
          <template v-slot:item.isActive="{ item }">
            <tr>
              <td>
                <v-autocomplete
                  v-model="item.isActive"
                  :items="isActivePropertyList()"
                  background-color="transparent"
                  flat
                  hide-details="auto"
                  placeholder=""
                  solo
                  color="#e21a1a"
                />
              </td>
            </tr>
          </template>
          <template v-slot:item.editAction="{ item }">
            <tr>
              <td>
                <v-btn @click="editItemActiveClick(item)" color="#e21a1a" :disabled="itemStatus(item)" text>
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div class="notification">
      <Notification />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import Notification from '@/components/Notification';
import config from '@/config/config';

export default {
  data() {
    return {
      currentPath: this.$route.path
    };
  },
  components: {
    Notification
  },
  computed: {
    products() {
      let productArray = [];
      if (this.menuList !== undefined && this.menuList !== null && this.menuListResult === true) {
        this.menuList.forEach((product) => {
          productArray.push(product);
        });
      }
      return JSON.parse(JSON.stringify(productArray));
    },
    headers() {
      return [
        {
          text: '',
          value: 'image',
          sortable: false
        },
        {
          text: 'Название',
          value: 'nameRu'
        },
        {
          text: 'Цена',
          value: 'price'
        },
        {
          text: 'Остаток',
          value: 'amountLeft'
        },
        { text: 'Товар в продаже', value: 'isActive' },

        {
          text: '',
          value: 'editAction',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ];
    },
    ...mapGetters('auth', ['userPerm']),
    ...mapGetters('admin', ['menuList']),
    ...mapGetters('admin', ['editItemErrorMessage']),
    ...mapGetters('admin', ['isMenuListLoading']),
    ...mapGetters('admin', ['menuListResult']),
    ...mapGetters('admin', ['isMenuListError']),
    ...mapGetters('admin', ['menuListErrorMessage']),
    ...mapGetters('admin', ['editItemResult'])
  },
  methods: {
    checkPermission(value) {
      return this.userPerm.find((el) => value.includes(el)) !== undefined;
    },
    url() {
      return window.location.origin + '/';
    },
    itemStatus(currItem) {
      let defaultItemIsActioveProperty = this.menuList.find((item) => item.token === currItem.token).isActive;
      return currItem.isActive === defaultItemIsActioveProperty;
    },
    editItemActiveClick(item) {
      item.cafeToken = this.$route.params.cafeToken;
      item.itemToken = item.token;
      this.editCurrItem(item)
        .then(() => {
          if (this.editItemResult === true) {
            this.showNotification('Товар успешно отредактирован', 'success', '2000', 'mdi-check');
          }
          if (this.editItemResult === false) {
            this.showNotification(this.editItemErrorMessage, 'success', '2000', 'mdi-check');
          }
        })
        .catch(() => {
          this.showNotification(`Возникла ошибка: ${this.editItemErrorMessage}`, 'red', '2000', 'mdi-check');
        });
    },
    isActivePropertyList() {
      return ReferenceHelper.getActiveProperty();
    },
    getCafesProduct() {
      // this.fetchMenuList(this.$route.params.cafeToken);
    },
    showNotification(message, color, timer, icon) {
      this.showSnack({
        message,
        color,
        timer,
        icon
      });
    },
    ...mapActions('admin', ['fetchMenuList']),
    ...mapActions('admin', ['editCurrItem']),
    ...mapActions('notification', ['showSnack'])
  },
  mounted() {
    this.getCafesProduct();
  }
};
</script>
<style scoped>
.notification {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
.v-image,
video {
  border-radius: 10px;
}
</style>
